import Link from "next/link";
import React from "react";
import { getStrapiMedia } from "../../lib/utils";
import styles from "./feature-card.module.scss";

const FeatureCard = ({ feature, style: parentStyle, mediaHeight }) => {
  const renderCard = (
    <div
      className={styles.card}
      style={{ ...parentStyle, cursor: feature.link && "pointer" }}
    >
      <div className={styles.title}>{feature.title}</div>
      {feature.media[0] && (
        <div
          className={styles.media}
          key={feature.title}
          style={{
            backgroundImage: `url(${getStrapiMedia(feature.media[0])})`,
            height: mediaHeight,
          }}
        ></div>
      )}
      {feature.subtitle && (
        <p className={styles.subtitle}>{feature.subtitle}</p>
      )}
      <div className={styles.description}>{feature.description}</div>
    </div>
  );

  return (
    <>
      {feature.link ? (
        <Link href={feature.link}>{renderCard}</Link>
      ) : (
        <div>{renderCard}</div>
      )}
    </>
  );
};

export default FeatureCard;
